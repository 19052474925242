:root {
  --background: #111;
  --shadow: #00000044;
  --primary: #fff;
  --primary2: #999;
  --primaryDim: #555;
  --accent: #00B050;
  --accent2: #24d574;
  --error: #ff2828;
}

body { margin: 0; }
input { background-color: transparent; border: none; outline: none; }
input:focus { background-color: transparent; border: none; outline: none; }
button { background-color: transparent; border: none; outline: none; padding: 0; }

*{ font-family: "Source Code Pro"; }

.app {
  width: 100vw; height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* background: linear-gradient(#3b406f, #463455); */
  background-color: var(--background);
}

.playerPanel {
  position: absolute;
  z-index: 2;

  top: 20px; left: 20px;
  color: var(--primaryDim);
}

.playerName {
  /* font-style: italic; */
  font-size: .8em;
  color: var(--primaryDim);
}

.activePlayerName {
  font-weight: bold;
  /* font-style: italic; */
  font-size: .8em;
  color: var(--accent);
}

.title {
  white-space: nowrap;

  font-family: "Unbounded";
  font-weight: 800;
  font-size: 5em;
  line-height: 1em;
  color: white;
}

.subtitle {
  white-space: nowrap;

  font-family: "Unbounded";
  font-weight: 500;

  font-size: 3em;
  color: white;
  
  padding-bottom: 1em;
}

.logo {
  z-index: 2;

  position: absolute;
  text-align: right;
  top: 20px; right: 20px;
  font-size: 50%;
}

.history {
  position: absolute;
  bottom: calc(20% + 50px);

  display: flex;
  flex-direction: column-reverse;

  /* width: max-content; */
  width: 100%;
  height: calc(80% - 50px);
}

.historyRow {
  display: flex;
  line-height: 1em;
  transition: all 300ms ease-out;

  margin: 5px 0 5px 0;
}

/* .historyRowPlayer {
  display: flex;
  line-height: 1em;
  transition: all 300ms ease-out;

  margin: 5px 0 5px 0;

  background-color: #00B050;
} */

.historyPlayerNumber{
  width: 40px; line-height: 20px;

  margin-left: 25%;
  text-align: center;
  font-family: "Kameron";
  font-weight: bold;
}

.historyWord {
  flex-grow: 1;
  padding-left: 10px; 
  line-height: 20px;
  font-size: .9em; 
  font-family: "Source Code Pro";
  text-transform: capitalize;
}

.slide-enter {
  transform: translateY(25px);
}
.slide-enter-active {
  transform: translateY(0);
  transition: 
    transform 300ms ease-out;
}

/* .slide-exit {
  transform: translateY(0);
  opacity: 1;
}
.slide-exit-active {
  transform: translateY(-50px);
  opacity: 0;

  transition: 
    transform 300ms ease-out,
    opacity 300ms ease-out;
} */

.startButton {
  white-space: nowrap;

  width: 50%; min-width: 150px;
  line-height: 40px;
  border-radius: 22px;

  font-weight: bold;
  font-size: 1em;

  color: var(--background);
  background-color: var(--accent);
  border: 2px solid var(--accent);
}

.startButton:hover {
  cursor: pointer;

  /* color: var(--background); */
  background-color: #00ff73;
  /* background: linear-gradient(var(--accent) 50%, var(--accent2)); */
  border: solid 2px #00ff73;
}

.roomDisplay {
  white-space: nowrap;
  width: 100%; min-width: 150px;
  line-height: 40px;
}

.roomButton {
  width: 100%;
  line-height: inherit;
  border-radius: 6px;

  font-size: 1em;

  color: var(--primary2);
  border: 2px solid var(--primary2);
}

.roomButton:hover {
  cursor: pointer;

  color: #ddd;
  background-color: #222;
  /* border: 2px solid var(--primary); */
}

.roomIdDisplay {
  width: 100%;
  line-height: inherit;
  border-radius: 6px;

  overflow: hidden;

  font-size: 1em;
  text-align: center;
  font-style: italic;

  color: var(--primary2);
  border: 2px solid var(--primary2);
}

.mainContent {
  width: 50%; height: auto;
  position: relative;
  top: 25%;
  background-color: cyan;
}

.wordForm {
  position: absolute;
  bottom: 20%;

  display: flex;
  width: 50%; height: 35px;
  border-bottom: 1px solid var(--primary);
  /* border-radius: 3px; */

  /* background-color: #ff00ff55; */
}

::placeholder {
  color: var(--primaryDim);
  opacity: 1;
}

.firstLetter {
  width: min-content; height: 35px; line-height: 35px;
  font-size: 1em;
  font-weight: bold;
  padding-left: 15px;

  color: var(--accent);
}

.wordField {
  width: 100%; height: auto; 
  line-height: 35px;

  text-transform: uppercase;

  font-size: 1em;
  color: var(--primary);
}

.wordDisplay {
  width: 100%; height: auto; 
  line-height: 35px;
  padding-left: 15px;
  font-size: .9em;

  background-color: transparent;
  background-clip: content-box;
  color: var(--primaryDim);
}


.pietimer {
  width: 21px; height: 20px;
  border-radius: 50%;
  margin: auto 5px;

  background-image: linear-gradient(90deg, #bbb 50%, var(--background) 50%);
  position: relative;
  overflow: hidden;
}

.pietimer:after {
  position: absolute;
  left: 50%;
  width: 100%;
  height: 100%;
  content: "";
  background: var(--background);
  transform-origin: 0 50%;
  animation: pieAniAfter 30s linear forwards;
}

@keyframes pieAniAfter {
  0% { transform: rotate(0deg); background: #bbb; }
  49.99% { transform: rotate(180deg); background: #bbb; }
  50% { transform: rotate(0deg); background: var(--background); }
  100% { transform: rotate(180deg); background: var(--background); }
}

.error {
  position: absolute;
  bottom: -25px;
  left: 10px;
  font-weight: bold;
  font-style: italic;
  font-size: .9em;
  color: var(--error);
  filter: drop-shadow(-2px 3px var(--shadow));
}
